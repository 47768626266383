<template>
    <section class="micro-store-server-content">
        <h2 v-if="content.title" class="server-title">
            {{ content.title }}
        </h2>
        <div class="server-content" v-html="content.content"></div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'MicroStoreServerContent',
    props: {
        content: {
            type: Object as PropType<Models.Content>,
            required: true,
        },
    },
})
</script>

<style lang="postcss" scoped>
.micro-store-server-content {
    .server-title {
        @apply mb-3 flex space-x-3 text-xl font-semibold;
    }
    .server-content {
        @apply prose-sm prose-gray prose-headings:font-semibold prose-p:text-base prose-ol:list-decimal prose-ul:list-disc prose-li:text-base prose-li:marker:font-semibold prose-li:marker:text-site-primary;

        @apply prose-p:my-0 !important;
    }

    ul,
    ol {
        @apply text-base;
    }
}
</style>
<style lang="postcss" scoped>
.server-content {
    a {
        @apply font-semibold text-site-primary hover:underline;
    }

    ul,
    ol {
        @apply text-base;
    }
}
</style>
